<template>
<div class="container">
    <div class="text-right">
    <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
    ></loading>
    </div>

<div class="text-left">
    <v-container fluid>
        <v-row>
        <v-col cols="3">
    <form>
        <v-text-field
            v-model="query"
            label="Search By Key"
            required>
        </v-text-field>
    </form>
    </v-col>
    <v-col cols="3" class="my-4 text-right">
        <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
        <v-btn small color="error" v-bind="attrs" v-on="on"><strong>Delete</strong></v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
        <template>
            <v-card>
            <v-card-text>
                <div class="text-h3 pa-12">
                Are you sure you want to delete all the selected Record(s)?
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                <v-btn
                    text
                    class="confirm_button mx-2"
                    v-on:click="dialog2 = false"
                    @click="deleteKeys"
                    >Yes</v-btn
                >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                Close
                </v-btn>
            </v-card-actions>
            </v-card>
        </template>
        </div>
        <div v-if="deleteItems.length == 0">
        <template>
            <v-card>
            <v-card-text class="center">
                <div class="text-h3 pa-12">
                Please Select Record(s) to delete
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                Close
                </v-btn>
            </v-card-actions>
            </v-card>
        </template>
        </div>
    </v-dialog>
        </v-col>
    </v-row>
    </v-container>
</div>
    <v-simple-table dense  class="elevation-1" v-if="filtered_keys.length">
            <template v-slot:default>
                <thead  style="background-color:blue">
                    <tr>
                        <th>
                            <v-checkbox
                                ref="n"
                                v-model="allSelected"
                                @click="selectAll"
                            ></v-checkbox>
                        </th>
                    <th class="text-left">
                        Key Name
                    </th>
                    <th class="text-left">
                        Data
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in filtered_keys" :key="index">
                        <td>
                            <v-checkbox
                                    ref="n"
                                    v-model="deleteItems"
                                    :value="item.name"
                                    :key="item.name + index"
                                    @click="addToDelete($event, item.name)"
                            ></v-checkbox>   
                        </td>
                        <td>{{ item.name}}</td>
                        <!-- <td><a :href="getkeyData(item.name)" class="link">View Data</a></td> -->
                        <td><v-btn small color="blue darken-1" @click="getkeyData(item.name)">
                            View Data
                        </v-btn></td>
                    </tr>
                </tbody>
            </template>
            </v-simple-table>
        <v-dialog
        v-model="moreInfoModal"
        max-width="1300"
        >
        <v-card>
            <v-row class="ma-5">
            <v-col cols="11" class="text-h3">
                Redis Data
            </v-col>
            <v-col cols="1" class="text-h3">
                <v-icon
                medium
                color="grey"
                class="ml-5"
                title="Close"
                @click="moreInfoModal = false, redis_data=''"
                >
                mdi-close
                </v-icon>
            </v-col>
            </v-row>
            <v-list
        subheader
        three-line
        >
        <div v-if="redis_data">
            <template>
                <p>{{redis_data}}</p>
            </template>
        </div>
        </v-list>
        </v-card>
        </v-dialog>
    <v-row justify="center">
    <v-dialog
    v-model="toggleUpdateModal"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
    >
</v-dialog>
</v-row>
    <!-- v-data-table-ends -->
</div>
</template>

<script>
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
components: { Index, Buttons, Loading, Pagination },
data() {
    return {
        toggleUpdateModal: false,
        isLoading: false,
        dialog2: false,
        deleteItems: [],
        redis_keys: [],
        options: {},
        redis_data: '',
        query: "",
        fullPage: true,
        checked_operationId: [],
        page: 1,
        allSelected: false,
        selectedKey: false,
        moreInfoModal: false,
        headers: [
            {
                text: "",
                value: "selectCheckbox",
                width: "50px",
                sortable: false,
                class: "v-data-table-header",
            },
            {
                text: "Key Name",
                value: "name",
                width: "200px",
                sortable: false,
                class: "v-data-table-header",
            },
            {
                text: "Data",
                value: "data",
                width: "200px",
                sortable: false,
                class: "v-data-table-header",
            },
        ],
    };
},
mounted(){
},
computed: {
    filtered_keys:function(){
        var filtered_data = []
        if(this.query !=""){
            this.redis_keys.forEach((key)=>{
                if(key.name.toLowerCase().includes(this.query.toLowerCase())){
                    filtered_data.push(key) 
                }
            })
            return filtered_data
        }else{
            return this.redis_keys
        }
    }

},
methods: {
    getKeys() {
        const path = this.$url("REDIS_KEYS");
        let check = this;
        this.isLoading = true;
        this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
            .get(path).then((res) => {
                console.log(res);
                let redis_data = res.data.payload.keys
                if (Array.isArray(redis_data) && redis_data.length) {
                    check.redis_keys = []
                    redis_data.forEach((key)=>{
                        let obj ={
                            name: key
                        }
                        check.redis_keys.push(obj)
                    })
                    
                }
                this.isLoading = false;
            });
    },
    getkeyData(key_name){
        const path = this.$url("GET_REDIS_KEY_DATA");
        this.moreInfoModal = true
        let check = this;
        this.isLoading = true;
        check.redis_data = ''
        this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
            .get(path, {
                params: { key: key_name },
            }).then((res) => {
                console.log(res);
                if (res.data.payload) {
                    check.redis_data = res.data.payload
                }
                this.isLoading = false;
            });

    },
    deleteKeys(e){
        e.preventDefault();
        let check = this;
        console.log(this.deleteItems);
        this.isLoading = true;
        this.$fetch({ requiresAuth: true, operation: "delete", vueScope: check })
        .delete(this.$url("DELETE_REDIS_KEYS"), {
            params: { keys: this.deleteItems },
        }).then((response) => {
            check.isLoading = false;
            if (response.data.statusCode == 200) {
                this.$swal.fire({
                    icon: "success",
                    title: "Selected key(s) has been deleted",
                    text: response.data.payload.message,
                }).then((result) => {
                    if (result) {
                        this.deleteItems = [];
                        this.getKeys();
                    } else {
                    // something other stuff
                    }
                });
            }
        });

    },
    selectAll(){
        let redis_data = this.redis_keys;
        if (this.allSelected == true) {
            if (Array.isArray(redis_data) && redis_data.length) {
                this.deleteItems = [];
                redis_data.forEach((key)=>{
                    this.deleteItems.push(key.name)
                })
            }
        }else if(this.allSelected == false) {
            this.deleteItems = []
        }
    },
    select: function () {
        this.allSelected = false;
    },
    checkIfOperationExistForModule(type) {
        return this.$utils.checkIfOperationExistForModule(this, type);
    },
    addToDelete(e, name) {
        this.checkedthis = false;
        if (e.target.checked) {
            if (this.checked_operationId.indexOf(e.target.value) == -1) {
                this.checked_operationId.push(e.target.value);
            }
        }else{
            this.checked_operationId.splice(this.checked_operationId.indexOf(e.target.value),1);
        }
        let checkcedstr = this.checked_operationId.join(",");
        this.checkcedstr = checkcedstr;
        console.log(this.checkcedstr);
    },
},
watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
        if (o) {
            this.getKeys()
        }
    }
    },
};
</script>